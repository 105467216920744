module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://cms.wildearthstaging.com/graphql","production":{"hardCacheMediaFiles":true},"html":{"generateWebpImages":true,"fallbackImageMaxWidth":700,"gatsbyImageOptions":{"breakpoints":[150,360,414,750,1080,1366,1920],"placeholder":"blurred"}},"develop":{"hardCacheMediaFiles":true,"hardCacheData":true},"schema":{"perPage":75,"requestConcurrency":12,"timeout":120000}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Wild Earth - As seen on Shark Tank - Dog Food","short_name":"Wild Earth","start_url":"/","background_color":"#fbfbfb","theme_color":"#004986","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"28daf8da54225a5ea3e21025547b3f2a"},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-T79QRDL","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{}},"dataLayerName":"dataLayer","routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":true,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
