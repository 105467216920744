import React, { createContext, useEffect } from "react"
import { v4 as uuidv4 } from "uuid"
import { useStore } from "../hooks/useStore"

// expose our global state stored in context
export const UserContext = createContext({})

export function UserProvider({ children }) {
  const { checkout } = useStore()
  useEffect(() => {
    if (checkout?.totalPriceV2) {
      window.dataLayer = window.dataLayer || []
      // elevar guest user data layer
      window.dataLayer.push({
        event: "dl_user_data",
        event_id: uuidv4(),
        cart_total: checkout.totalPriceV2.amount,
        user_properties: {
          visitor_type: "guest",
        },
      })
    }
  }, [checkout])

  return <UserContext.Provider value={{}}>{children}</UserContext.Provider>
}
