// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-preview-index-js": () => import("./../../../src/pages/preview/index.js" /* webpackChunkName: "component---src-pages-preview-index-js" */),
  "component---src-templates-category-archive-js": () => import("./../../../src/templates/CategoryArchive.js" /* webpackChunkName: "component---src-templates-category-archive-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/Page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/Post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-tag-archive-js": () => import("./../../../src/templates/TagArchive.js" /* webpackChunkName: "component---src-templates-tag-archive-js" */)
}

