import { useLocation } from "@reach/router"
import qs from "query-string"
import React, { createContext, useEffect } from "react"
import UniversalCookies from "universal-cookie"
import {
  ADDITIONAL_PARAMS_TO_COOKIES,
  ELEVAR_VISITOR_INFO_COOKIE,
  SHOPIFY_NATIVE_UTM_COOKIE,
} from "../constants"
import { addHoursToNow } from "../utils/addHoursToNow"

// expose our global state stored in context
export const CookieContext = createContext({})

const universalCookies = new UniversalCookies()

export function CookieProvider({ children }) {
  const { search } = useLocation()

  useEffect(() => {
    const params = qs.parse(search, {
      arrayFormat: "bracket",
    })

    // convert our URL params into an object where each key is the parameter name
    // for arrays, we expect each parameter value to exist in the following format:
    //
    // foo[]=123&foo[]=456&foo[]=789
    //
    // this means that if there's accidentally two parameters with the same name, the most recent is used
    // unless it's meant to be an array value of said parameter
    const utms = Object.fromEntries(
      Object.entries(params).filter(([utm]) => /^utm_/.test(utm))
    )

    const matched = Object.entries(params).filter(
      ([p]) => !!ADDITIONAL_PARAMS_TO_COOKIES[p]
    )

    const setAdditionalCookies = () => {
      if (matched.length) {
        matched.forEach(([name, value]) => {
          const config = ADDITIONAL_PARAMS_TO_COOKIES[name]

          universalCookies.set(name, value, {
            path: config?.path || "/",
            expires: config?.expires || addHoursToNow(24),
            sameSite: config?.sameSite || "none",
            secure: config?.secure,
          })
        })
      }
    }

    const setShopifyCookie = () => {
      const value = qs.stringify({
        ...utms,
        utm_data_source: "shopify_cookie",
        utm_timestamp: new Date().toISOString(),
      })

      if (Object.keys(utms).length) {
        universalCookies.set(SHOPIFY_NATIVE_UTM_COOKIE, value, {
          path: "/",
          expires: addHoursToNow(24),
          sameSite: "lax",
        })
      }
    }

    const setElevarVisitorInfoCookie = () => {
      const { gclid, fbclid } = params
      const value = qs.stringify({
        ...utms,
        gclid,
        fbclid,
      })

      if (Object.keys(utms).length || gclid || fbclid) {
        universalCookies.set(ELEVAR_VISITOR_INFO_COOKIE, value, {
          path: "/",
          expires: addHoursToNow(24),
          sameSite: "lax",
        })
      }
    }

    function go() {
      setShopifyCookie()
      setAdditionalCookies()
      setElevarVisitorInfoCookie()
    }

    go()
  }, [])

  /**
   * Retrieves an object of key value pairs where each key corresponds to a UTM parameter and
   * the value corresponds to the parameter value
   *
   * @returns {Object|null}
   */
  const getUTMs = () =>
    universalCookies.get(SHOPIFY_NATIVE_UTM_COOKIE)
      ? qs.parse(universalCookies.get(SHOPIFY_NATIVE_UTM_COOKIE))
      : null

  /**
   * Plucks out a cookie from cookie header when given a name
   *
   * @param {string} name cookie name
   * @param {Object} options options
   * @returns {Object|string}
   */
  const getCookie = (name, { doNotParse, ...options }) => {
    const cookie = universalCookies.get(name, { doNotParse })

    if (!cookie) {
      return null
    }

    if (options?.parse) {
      return options?.named ? { [name]: qs.parse(cookie) } : qs.parse(cookie)
    }

    return options?.named ? { [name]: cookie } : cookie
  }

  return (
    <CookieContext.Provider
      value={{
        getUTMs,
        getCookie,
      }}
    >
      {children}
    </CookieContext.Provider>
  )
}
