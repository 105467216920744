import Bugsnag from "@bugsnag/js"
import BugsnagPluginReact from "@bugsnag/plugin-react"
import { Location } from "@reach/router"
import { TontineProvider } from "@tontine/tontine-react"
import React from "react"
import { CookieProvider } from "./src/providers/CookieProvider"
import { StoreProvider } from "./src/providers/StoreProvider"
import { UserProvider } from "./src/providers/UserProvider"

Bugsnag.start({
  apiKey: process.env.GATSBY_APP_BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.CONTEXT || process.env.NODE_ENV,
  appVersion: process.env.DEPLOY_ID,
})

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React)

export const wrapRootElement = ({ element }) => {
  return (
    <ErrorBoundary>
      <Location>
        {context => {
          return (
            <StoreProvider>
              <UserProvider>
                <CookieProvider>
                  <TontineProvider apiUrl="api.tontine.ai">
                    {element}
                  </TontineProvider>
                </CookieProvider>
              </UserProvider>
            </StoreProvider>
          )
        }}
      </Location>
    </ErrorBoundary>
  )
}
