import { graphql, useStaticQuery } from "gatsby"
import fetch from "isomorphic-fetch"
import { find, flow, partialRight, property, some } from "lodash"
import React, { createContext, useEffect, useState } from "react"
import ShopifyClient from "shopify-buy"
import { isBrowser } from "../utils/isBrowser"

// expose our global state stored in context
export const StoreContext = createContext({})

const client = ShopifyClient.buildClient(
  {
    storefrontAccessToken: process.env.GATSBY_APP_STOREFRONT_ACCESS_TOKEN,
    domain: process.env.GATSBY_APP_TLD,
  },
  fetch
)

// we shouldn't have to create a new client every time we want to send off a query, let's add it to our global state
export const INITIAL_STORE_STATE = {
  client,
  checkout: {
    lineItems: [],
  },
  addLineItems: () => {},
  removeLineItems: () => {},
  updateLineItems: () => {},
  replaceLineItem: () => {},
  products: [],
  getProduct: () => null,
  getVariant: () => null,
  getProductByGid: () => null,
  getVariantByGid: () => null,
  getProductByVariantMatch: () => null,
  isUpdating: false,
  addedVariantIds: [],
}

export function StoreProvider({ children }) {
  const [checkout, setCheckout] = useState(INITIAL_STORE_STATE.checkout)
  const [updating, setUpdating] = useState(INITIAL_STORE_STATE.isUpdating)
  const [addedVariantIds, setAddedVariantIds] = useState(
    INITIAL_STORE_STATE.addedVariantIds
  )

  const graphqlResult = useStaticQuery(graphql`
    {
      allShopifyProduct {
        nodes {
          featuredImage {
            originalSrc
            localFile {
              childImageSharp {
                gatsbyImageData(width: 68, height: 68, placeholder: BLURRED)
              }
            }
          }
          hasOnlyDefaultVariant
          id
          options {
            id
            name
            position
            values
          }
          metafields {
            key
            namespace
            value
          }
          productType
          shopifyId
          status
          storefrontId
          tags
          title
          variants {
            displayName
            id
            inventoryQuantity
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 68, height: 68)
                }
              }
              altText
            }
            metafields {
              key
              value
            }
            price
            selectedOptions {
              name
              value
            }
            shopifyId
            sku
            storefrontId
            title
            weight
            weightUnit
          }
          vendor
        }
      }
    }
  `)

  const products = graphqlResult?.allShopifyProduct?.nodes

  /**
   * We can only save the checkout ID to local storage in the browser,
   * so we must checkout if we're in a browser environment first
   *
   * @param {Object} payload shopify checkout object
   */
  const setCheckoutToLocalStorage = payload => {
    if (isBrowser()) {
      localStorage.setItem("checkoutId", payload.id)
    }

    setCheckout(payload)
  }

  useEffect(() => {
    // we need to check to see if the user has previously added products to their cart
    // if they did, we need to retrieve the existing cart in order to provide the opportunity to checkout,
    // otherwise, we can treat the user as a newbie
    async function initializeCheckout() {
      // to keep track of checkouts, we can store the checkout ID in local storage
      const existingCheckoutId = isBrowser()
        ? localStorage.getItem("checkoutId")
        : null

      if (existingCheckoutId && existingCheckoutId !== "null") {
        try {
          const existingCheckout = await client.checkout.fetch(
            existingCheckoutId
          )

          // Shopify checkout doesn't alert the Gatsby that checkout has been completed and thus, localStorage will contain pre-checkout state
          if (!existingCheckout.completedAt) {
            setCheckoutToLocalStorage(existingCheckout)
            return
          }
        } catch (e) {
          localStorage.setItem("checkoutId", null)
          console.error(e)
        }
      }

      // newbie user, create a fresh checkout
      const checkout = await client.checkout.create()
      setCheckoutToLocalStorage(checkout)
    }

    async function go() {
      await initializeCheckout()
    }

    go()
  }, [])

  /**
   * Global func to update line items in a shopify checkout object
   *
   * @param {string} checkoutId shopify checkout ID
   * @param {Object[]} lineItems list of line items and properties to update
   */
  const updateLineItems = (checkoutId, lineItems) => {
    setUpdating(true)

    return client.checkout
      .updateLineItems(checkoutId, lineItems)
      .then(payload => {
        setCheckout(payload)
        setUpdating(false)
        return payload
      })
  }

  /**
   * Global func to remove line items from a shopify checkout object
   *
   * @param {string} checkoutId shopify checkout ID
   * @param {Object[]} lineItems list of line item IDs to remove
   */
  const removeLineItems = (checkoutId, lineItems) => {
    setUpdating(true)

    return client.checkout
      .removeLineItems(checkoutId, lineItems)
      .then(payload => {
        setCheckout(payload)
        setUpdating(false)
        return payload
      })
  }

  /**
   * Global func to add line items to a shopify checkout object
   *
   * @param {string} checkoutId shopify checkout ID
   * @param {Object[]} lineItems list of line items
   * @param {string} lineItems.variantId
   * @param {number} lineItems.quantity
   */
  const addLineItems = (checkoutId, lineItems) => {
    setUpdating(true)
    setAddedVariantIds([
      ...addedVariantIds,
      lineItems[lineItems.length - 1].variantId,
    ])

    return client.checkout.addLineItems(checkoutId, lineItems).then(payload => {
      setCheckout(payload)
      setUpdating(false)
      return payload
    })
  }

  const replaceLineItem = async (checkoutId, replacement) => {
    setUpdating(true)

    await client.checkout.removeLineItems(checkoutId, [replacement.from])

    await client.checkout
      .addLineItems(checkoutId, [replacement.to])
      .then(payload => setCheckout(payload))

    setUpdating(false)
  }

  /**
   * Get a shopify Product object by a given Shopify GID
   *
   * @param {string} shopifyId Shopify GID for top level Product object
   * @param {Object[]} collection list of Product objects provided by GQL query
   * @returns {Object|undefined}
   */
  const getProductByGid = (shopifyId, collection) =>
    (collection || products).find(product => product.shopifyId === shopifyId)

  /**
   * Get a shopify ProductVariant object by a given Shopify GID. When provided with a collection, this function will
   * automatically pluck out all variants and flatten into a single level array of objects.
   *
   * @param {string} shopifyId Shopify GID for top level Product object
   * @param {Object[]} collection list of Product objects provided by GQL query
   * @returns {Object|undefined}
   */
  const getVariantByGid = (shopifyId, collection) =>
    (collection || products)
      .map(p => p.variants)
      .flat()
      .find(v => v.shopifyId === shopifyId)

  /**
   * A deep search to get a shopify Product object when provided with a query object to be matched
   * against a products list of variants.
   *
   * @param {Object} query query object to be matched against each Variant object
   * @param {Object[]} collection list of Product objects provided by GQL query
   * @returns {Object|undefined}
   */
  const getProductByVariantMatch = (query, collection) =>
    find(
      collection || products,
      flow([property("variants"), partialRight(some, query)])
    )

  /**
   * Get a shopify Product object when provided with a query object to be matched
   * against a products properties.
   *
   * @param {Object} query query object to be matched against each Product object
   * @param {Object[]} collection list of Product objects provided by GQL query
   * @returns {Object|undefined}
   */
  const getProduct = (query, collection) => find(collection || products, query)

  /**
   * Get a shopify ProductVariant object when provided with a query object to be matched
   * against a variants properties.
   *
   * @param {Object} query query object to be matched against each Variant object
   * @param {Object[]} collection list of Variant objects provided by GQL query
   * @returns {Object|undefined}
   */
  const getVariant = (query, collection) =>
    find(collection || products.map(p => p.variants).flat(), query)

  return (
    <StoreContext.Provider
      value={{
        ...INITIAL_STORE_STATE,
        checkout,
        updateLineItems,
        removeLineItems,
        addLineItems,
        replaceLineItem,
        isUpdating: updating,
        addedVariantIds,
        products,
        getVariant,
        getProduct,
        getProductByGid,
        getVariantByGid,
        getProductByVariantMatch,
      }}
    >
      {children}
    </StoreContext.Provider>
  )
}
